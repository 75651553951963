import { Core } from "@faire/design-tokens";
import { useQuery as usePromoBannerQuery } from "@faire/web-api/api/messaging/promo_banner/get-hooks";
import { ConductorSurface } from "@faire/web-api/indigofair/data/ConductorSurface";
import { FreeShippingReason } from "@faire/web-api/indigofair/data/FreeShippingReason";
import { pathMatchesRoute } from "@faire/web-api/pathMatchesRoute";
import { route as amexRoute } from "@faire/web-api/routes/amex";
import { route as brandApplicationRoute } from "@faire/web-api/routes/apply";
import { route as brandAdApplicationRoute } from "@faire/web-api/routes/apply/ad";
import { route as brandInvoiceTokenRoute } from "@faire/web-api/routes/brand-invoice/brandInvoiceToken";
import { route as brandPageRoute } from "@faire/web-api/routes/brand/brandToken";
import { route as messagesRoute } from "@faire/web-api/routes/messages";
import { route as messagesWithOtherPartyRoute } from "@faire/web-api/routes/messages/otherPartyToken";
import { route as posConsentRoute } from "@faire/web-api/routes/point-of-sale/consent";
import { route as collectionRoute } from "@faire/web-api/routes/product-based-collection/product_based_collection_token";
import { route as productRoute } from "@faire/web-api/routes/product/productToken";
import { route as brandReferralRoute } from "@faire/web-api/routes/user/brand-referrals";
import { getGlobalProperty } from "@faire/web/common/globals/getGlobalProperty";
import { getCurrentPath } from "@faire/web/common/Path";
import { parseQueryParams } from "@faire/web/common/query-params/parseQueryParams";
import { isNotUndefined } from "@faire/web/common/typescriptUtils";
import { useViewport } from "@faire/web/ui/hooks/useViewport";
import { matchPathWithLocale } from "@faire/web/ui/routing/react-utils";
import differenceInDays from "date-fns/differenceInDays";
import isBefore from "date-fns/isBefore";
import compact from "lodash/compact";
import some from "lodash/some";
import { useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";

import {
  LOGGED_IN_PROMO_BANNERS,
  LOGGED_OUT_PROMO_BANNERS,
  PromoBannerType,
} from "@faire/retailer/components/Notifications/PromoBanner/bannerOrder";
import { useConductorComponent } from "@faire/retailer/features/Conductor/useConductorComponent";
import { usePOSSummary } from "@faire/retailer/features/PointOfSale/hooks/usePOSSummary";
import { useFaireDirectFirstOrderIncentive } from "@faire/retailer/hooks/FaireDirect/useFaireDirectFirstOrderIncentive/useFaireDirectFirstOrderIncentive";
import { useFaireDirectMarketplaceIncentive } from "@faire/retailer/hooks/FaireDirect/useFaireDirectMarketplaceIncentive/useFaireDirectMarketplaceIncentive";
import { useFirstOrderIncentiveStore } from "@faire/retailer/hooks/firstOrderIncentive/useFirstOrderIncentiveStore";
import { useIsBrandPreview } from "@faire/retailer/lib/getIsBrandPreview";
import { useIsLoggedInRetailer } from "@faire/retailer/lib/isLoggedInRetailer";
import { useAlertBannerStore } from "@faire/retailer/packages/core/services/AlertBanner/AlertBannerStore";
import { getBrand } from "@faire/retailer/serialized-data/getBrand";
import { getInsiderMemershipInformation } from "@faire/retailer/serialized-data/getInsiderMembershipInformation";
import { specialHolidayNet90Active } from "@faire/retailer/settings/getSettingSpecialHolidayNet90";

import { useMarketStore } from "../domain/Events/FaireMarkets/MarketStore";
import { useOpeningOrderIncentivesStore } from "../domain/OpeningOrderIncentivesStore";
import { useOpenWithFaireStore } from "../domain/OpenWithFaireStore";
import { useRetailerStore } from "../domain/RetailerStore";
import { useMilestone } from "../domain/useMilestone";
import { useUserStore } from "../domain/UserStore";

import { useFreeShippingContextStore } from "./FreeShippingContextStore";
import { useTopOfPageContextStore } from "./TopOfPageContextStore";

export const BANNER_INTERVAL_MS = 7500;

export const usePromoBanners = () => {
  const location = useLocation();
  const { isMobile, isDesktopAndAbove } = useViewport();
  const isLoggedInRetailer = useIsLoggedInRetailer();
  const isBrandPreview = useIsBrandPreview();
  const { hasLinkedPOS } = usePOSSummary();

  const {
    conductorBuilderModelName,
    conductorSduiViewLayout: sduiBannersViewLayout,
  } = useConductorComponent(ConductorSurface.SITE_WIDE_TOP_BANNER);
  const { hasIncentive: hasFaireDirectFirstOrderIncentive } =
    useFaireDirectFirstOrderIncentive();
  const { hasIncentive: hasFaireDirectMarketplaceIncentive } =
    useFaireDirectMarketplaceIncentive();
  const { data: promoBannerResult } = usePromoBannerQuery(undefined, {
    enabled: isLoggedInRetailer,
  });
  const inProductMessagingBanners = promoBannerResult?.banner_messages;

  const [{ bannerHeight, isBannerInView }, setStore] = useTopOfPageContextStore(
    ["bannerHeight", "isBannerInView"]
  );
  const { banner } = useAlertBannerStore(["banner"]);
  const { hasFirstOrderIncentive } = useFirstOrderIncentiveStore();
  const [{ freeShippingStatuses }] = useFreeShippingContextStore([
    "freeShippingStatuses",
  ]);
  const { showCountdownBanner } = useMarketStore(["showCountdownBanner"]);
  const { showOpeningOrderIncentives, openingOrderIncentivesProps } =
    useOpeningOrderIncentivesStore([
      "showOpeningOrderIncentives",
      "openingOrderIncentivesProps",
    ]);
  const { showOwFEntrypointEligibleOnly } = useOpenWithFaireStore([
    "showOwFEntrypointEligibleOnly",
  ]);
  const {
    retailer,
    showAmexBanner: _showAmexBanner,
    showWarehouseFlashSale,
    isRetailerCreatedWithinLast30Days,
  } = useRetailerStore([
    "retailer",
    "showAmexBanner",
    "showWarehouseFlashSale",
    "isRetailerCreatedWithinLast30Days",
  ]);
  const { user } = useUserStore(["user"]);

  const {
    hasOccurred: hasSentReferralBonusFollowup,
    count: referralBonusFollowupCount,
  } = useMilestone("HAS_SENT_REFERRAL_BONUS_FOLLOW_UP");

  const setBannerHeight = useCallback(
    (height: number) => {
      setStore({ bannerHeight: height });
    },
    [setStore]
  );

  const isAppMobileWebView = useMemo(() => {
    const queryParams = parseQueryParams(location.search);
    return queryParams.get("native_env") === "true";
  }, [location.search]);

  const showingBrandsCounterBanner = useMemo(() => {
    return (
      !user &&
      !(
        pathMatchesRoute(getCurrentPath(), productRoute) ||
        pathMatchesRoute(getCurrentPath(), collectionRoute)
      )
    );
  }, [user]);

  const hasBrandsReferralWithBonusOffers = useMemo(() => {
    const bonusOffersReferralNamesList = getGlobalProperty<Array<string>>(
      "referralBonusOfferReferralNames"
    );
    return (
      !!bonusOffersReferralNamesList && bonusOffersReferralNamesList.length > 0
    );
  }, []);

  const showReferralFollowupBanner = useMemo(() => {
    const pathName = location.pathname;
    const isOnReferralPage = matchPathWithLocale(
      pathName ?? "",
      brandReferralRoute
    );
    return (
      !isOnReferralPage &&
      !hasSentReferralBonusFollowup &&
      hasBrandsReferralWithBonusOffers
    );
  }, [
    hasBrandsReferralWithBonusOffers,
    hasSentReferralBonusFollowup,
    location.pathname,
  ]);

  const shouldPrioritizeReferralFollowupBanner =
    referralBonusFollowupCount <= 5 && showReferralFollowupBanner;

  const showPosIntegrationBanner = useMemo(() => {
    const firstOrder = retailer?.first_order_at;
    const now = Date.now();
    return (
      isNotUndefined(firstOrder) &&
      differenceInDays(now, firstOrder) >= 1 &&
      differenceInDays(now, firstOrder) < 10 &&
      !hasLinkedPOS
    );
  }, [retailer?.first_order_at, hasLinkedPOS]);

  const showBrandPreviewBanner = isBrandPreview && !getBrand()?.first_active_at;
  const showFaireValuesBanner = !!inProductMessagingBanners?.length;
  const showConductorBanners =
    !!conductorBuilderModelName || !!sduiBannersViewLayout?.content?.length;

  const showOpenWithFaireEligibleToApplyBanner = showOwFEntrypointEligibleOnly;

  const getExpiryDateForFreeShippingReason = useCallback(
    (reason: FreeShippingReason) => {
      const freeShippingStatus = freeShippingStatuses?.find(
        (status) => status.reason === reason
      );
      if (!freeShippingStatus || !freeShippingStatus.expiry) {
        return;
      }
      return new Date(freeShippingStatus.expiry);
    },
    [freeShippingStatuses]
  );

  const employeeGrantedFreeFreightDeadline = useMemo(() => {
    return getExpiryDateForFreeShippingReason(
      FreeShippingReason.EMPLOYEE_GRANTED_FREE_SHIPPING
    );
  }, [getExpiryDateForFreeShippingReason]);

  const showingEmployeeGrantedFreeFreightBanner = useMemo(() => {
    if (!employeeGrantedFreeFreightDeadline) {
      return false;
    }
    return isBefore(Date.now(), employeeGrantedFreeFreightDeadline);
  }, [employeeGrantedFreeFreightDeadline]);

  const isBlacklisted = useMemo(() => {
    const getCurrentPath = location.pathname ?? "";
    return (
      some(ROUTE_BLACKLIST, (route) =>
        pathMatchesRoute(getCurrentPath, route)
      ) ||
      (pathMatchesRoute(getCurrentPath, brandPageRoute) &&
        isLoggedInRetailer &&
        isMobile)
    );
  }, [isLoggedInRetailer, isMobile, location.pathname]);

  const showAmexBanner = useMemo(() => {
    return _showAmexBanner || pathMatchesRoute(getCurrentPath(), amexRoute);
  }, [_showAmexBanner]);

  const isAlertBannerVisible = banner?.visible;

  const backgroundColor = useMemo(() => {
    return isAlertBannerVisible ? Core.surface.inverse : Core.surface.default;
  }, [isAlertBannerVisible]);

  const textColor = useMemo(() => {
    return isAlertBannerVisible ? Core.text.default : Core.text.inverse;
  }, [isAlertBannerVisible]);

  const showMarketCountdownBanner = useMemo(() => {
    return (
      showCountdownBanner &&
      // When market banner should show, but retailer has First
      //   Order Incentive, make the Faire Values rotating banner top priority.
      // https://www.notion.so/faire/2023-FSM-New-Retailer-Proposal-a0a81622db7841a29e63099fcb832ba8
      !hasFirstOrderIncentive
    );
  }, [hasFirstOrderIncentive, showCountdownBanner]);

  const showSpecialHolidayNet90 = useMemo(() => {
    return (
      !showOpenWithFaireEligibleToApplyBanner && specialHolidayNet90Active()
    );
  }, [showOpenWithFaireEligibleToApplyBanner]);

  const showInsiderAutoEnrollBanner = useMemo(() => {
    return (
      getInsiderMemershipInformation()?.state === "AUTO_ENROLLED_TRIAL" &&
      (getInsiderMemershipInformation()?.current_period_end_at ?? 0) >
        Date.now()
    );
  }, []);

  const showWarehouseSaleBanner =
    showWarehouseFlashSale && !isRetailerCreatedWithinLast30Days;

  const showOpeningOrderIncentivesBanner =
    !!showOpeningOrderIncentives && !!openingOrderIncentivesProps;

  const showFaireDirectIncentivesBanner = useMemo(() => {
    return (
      hasFaireDirectFirstOrderIncentive || hasFaireDirectMarketplaceIncentive
    );
  }, [hasFaireDirectFirstOrderIncentive, hasFaireDirectMarketplaceIncentive]);

  const isBannerVisible = useCallback(
    (type: PromoBannerType): boolean => {
      switch (type) {
        case PromoBannerType.FAIRE_DIRECT_INCENTIVES:
          return showFaireDirectIncentivesBanner;
        case PromoBannerType.MARKET_COUNTDOWN:
          return showMarketCountdownBanner;
        case PromoBannerType.CONDUCTOR_BANNERS:
          return showConductorBanners;
        case PromoBannerType.FAIRE_VALUES:
          return showFaireValuesBanner;
        case PromoBannerType.FIRST_ORDER_INCENTIVE:
          return hasFirstOrderIncentive;
        case PromoBannerType.INSIDER_AUTO_ENROLL:
          return showInsiderAutoEnrollBanner;
        case PromoBannerType.SPECIAL_HOLIDAY_NET90:
          return showSpecialHolidayNet90;
        case PromoBannerType.OPENING_ORDER_INCENTIVES:
          return showOpeningOrderIncentivesBanner;
        case PromoBannerType.AMEX_INCENTIVE:
          return showAmexBanner;
        case PromoBannerType.BRAND_PREVIEW:
          return showBrandPreviewBanner;
        case PromoBannerType.BRANDS_COUNTER:
          return showingBrandsCounterBanner;
        case PromoBannerType.WAREHOUSE_SALE:
          return showWarehouseSaleBanner;
        case PromoBannerType.EMPLOYEE_GRANTED_FREE_FREIGHT:
          return showingEmployeeGrantedFreeFreightBanner;
        case PromoBannerType.OPEN_WITH_FAIRE:
          return showOpenWithFaireEligibleToApplyBanner;
        case PromoBannerType.POS_INTEGRATION:
          return showPosIntegrationBanner;
        case PromoBannerType.REFERRAL_FOLLOWUP:
          return showReferralFollowupBanner;
        default:
          return false;
      }
    },
    [
      hasFirstOrderIncentive,
      showAmexBanner,
      showBrandPreviewBanner,
      showFaireDirectIncentivesBanner,
      showFaireValuesBanner,
      showInsiderAutoEnrollBanner,
      showMarketCountdownBanner,
      showOpenWithFaireEligibleToApplyBanner,
      showOpeningOrderIncentivesBanner,
      showPosIntegrationBanner,
      showReferralFollowupBanner,
      showConductorBanners,
      showSpecialHolidayNet90,
      showWarehouseSaleBanner,
      showingBrandsCounterBanner,
      showingEmployeeGrantedFreeFreightBanner,
    ]
  );

  const banners = useMemo(() => {
    // FD-114601: Most banners should not be visible in app mobile view
    //   to prevent user from escaping into the rest of website
    if (isBlacklisted || isAppMobileWebView) {
      return [];
    }

    // Brand preview banner, should override any banner meant for retailers
    if (showBrandPreviewBanner) {
      return [PromoBannerType.BRAND_PREVIEW];
    }

    if (
      shouldPrioritizeReferralFollowupBanner &&
      !showFaireDirectIncentivesBanner
    ) {
      return [PromoBannerType.REFERRAL_FOLLOWUP];
    }

    const applicableBanners = isLoggedInRetailer
      ? LOGGED_IN_PROMO_BANNERS
      : LOGGED_OUT_PROMO_BANNERS;
    const visibleBanners = applicableBanners
      .map((types) =>
        Array.isArray(types) ? types.filter(isBannerVisible) : types
      )
      .filter((types) =>
        Array.isArray(types) ? types.length > 0 : isBannerVisible(types)
      );

    if (visibleBanners.length === 0) {
      return [];
    }

    const shownBanners = Array.isArray(visibleBanners[0])
      ? visibleBanners[0]
      : [visibleBanners[0]];

    return compact(shownBanners);
  }, [
    isAppMobileWebView,
    isBannerVisible,
    isBlacklisted,
    isLoggedInRetailer,
    shouldPrioritizeReferralFollowupBanner,
    showBrandPreviewBanner,
    showFaireDirectIncentivesBanner,
  ]);

  const isVisible = useMemo(() => {
    return banners.length > 0;
  }, [banners.length]);

  const visibleHeight = useMemo(() => {
    if (!isVisible) {
      return 0;
    }
    return isBannerInView ? bannerHeight : 0;
  }, [bannerHeight, isBannerInView, isVisible]);

  const promoBannerHeight = useMemo(() => {
    if (showCountdownBanner && isDesktopAndAbove) {
      return bannerHeight;
    }
    return visibleHeight;
  }, [bannerHeight, isDesktopAndAbove, showCountdownBanner, visibleHeight]);

  return {
    backgroundColor,
    bannerHeight,
    banners,
    employeeGrantedFreeFreightDeadline,
    inProductMessagingBanners,
    isVisible,
    promoBannerHeight,
    sduiBannersViewLayout,
    setBannerHeight,
    showInsiderAutoEnrollBanner,
    showOpenWithFaireEligibleToApplyBanner,
    showingBrandsCounterBanner,
    showingEmployeeGrantedFreeFreightBanner,
    textColor,
    visibleHeight,
  };
};

const ROUTE_BLACKLIST = [
  brandApplicationRoute,
  brandAdApplicationRoute,
  messagesRoute,
  messagesWithOtherPartyRoute,
  brandInvoiceTokenRoute,
  brandReferralRoute,
  posConsentRoute,
] as const;
